








import {Vue, Component, Prop} from 'vue-property-decorator';
import SummaryWidget from '@/app/modules/cart/components/partials/SummaryWidget.vue';
import AmountsFormattedInterface from '@/shared/lib/interfaces/AmountsFormattedInterface';

@Component({
  components: {
    SummaryWidget,
  },
})
export default class OrderSummaryWidget extends Vue {
  /**
   * Props
   */
  @Prop() private totals!: AmountsFormattedInterface;
}
